import axios from 'axios';
import { getUrlParams, createUrlParams } from 'src/utilities/url';
import { Product } from 'src/models/product.model';

const state = {
  loadingProducts: true,
  noProductsFound: false,
  productCodes: [],
  products: [],
  showMobileFilters: false,
  totalProducts: 0,
  currentPage: 1,
  totalPages: 1,
  layoutType: '',
  blockSize: 4,
  rangeFrom: 0,
  pageSize: 16,
  queryString: '',
  aggregations: false,
  pricingQueryString: '',
  activeFilters: {},
  filters: [],
  pageSizes: [],
  sort: '',
  sortingType: 0,
  sortingOptions: [],
  customerListId: '',
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  }
};

const getters = {
  pageTitle(state) {
    return state.pageTitle ? state.pageTitle : state.queryString;
  },
  layoutType(state) {
    return state.layoutType;
  },
  language(state, getters, rootState, rootGetters) {
    return rootGetters.language;
  },
  blockSize(state) {
    return state.blockSize;
  },
  totalProducts(state) {
    return state.totalProducts;
  },
  totalPages() {
    return Math.ceil(state.totalProducts / state.pageSize);
  },
  currentPage(state) {
    return state.currentPage;
  },
  loadingProducts(state) {
    return state.loadingProducts;
  },
  pageRange(state, getters) {
    let to;
    let pageFillCount = state.totalProducts % state.pageSize;
    if (pageFillCount && getters.totalPages === state.currentPage) {
      to = state.rangeFrom + pageFillCount;
    } else {
      to = state.pageSize + state.rangeFrom;
    }
    return {
      from: state.rangeFrom + 1,
      to: to
    };
  },
  showMobileFilters(state, getters, rootState, rootGetters) {
    if (rootGetters.screenWidth < 991) {
      return state.showMobileFilters;
    } else {
      return true;
    }
  },
  noProductsFound(state) {
    return state.noProductsFound;
  },
  searchConfig(state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: state.activeFilters,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  filters(state) {
    return state.filters;
  },
  elasticProductsEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const from = state.rangeFrom;
    const language = rootGetters.language;
    const size = state.pageSize;
    const aggregations = state.aggregations;
    const pricingQueryString = state.pricingQueryString;
    const customerListId = state.customerListId;
    const query = !state.queryString ? '' : state.queryString;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    const groupProducts = rootGetters.groupProducts;
    const sort = state.sort;
    const sortingType = state.sortingType;
    var sortingstring = ''

    if (sort !== null) sortingstring = '&sort=' + sort + '|' + sortingType;

    return `${endpoint}?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=${groupProducts}`;
  },
  productInformationEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  // BESPOKE TEBE
  // Multiselect filters
  elasticAggregationProductsEndpoint(state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const language = rootGetters.language;
    const customerListId = state.customerListId;
    const query = !state.queryString ? '' : state.queryString;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    var sortingstring = ''

    return `${endpoint}/GetAggregations?lang=${language}&query=${query}&customerId=${customerId}&listId=${customerListId}&client=${client}`;
  },
  // END BESPOKE TEBE
  customerListId(state) {
    return state.customerListId;
  },
  productCodes(state) {
    return state.productCodes;
  },
  // BESPOKE
  activeFilters(state) {
    return state.activeFilters;
  }
  // END BESPOKE
};

const mutations = {
  setLoadingStatus(state, payload) {
    state.loadingProducts = payload;
  },
  setSortingOptions(state, payload) {
    state.sortingOptions = payload;
  },
  setProducts(state, products) {
    state.products = products;
  },
  noProductsFound(state, productsFound) {
    state.noProductsFound = productsFound;
  },
  setProductCodes(state, productCodes) {
    state.productCodes = productCodes;
  },
  setFilters(state, aggregations) {
    let filters = aggregations !== null ? [...aggregations] : state.filters;
    // Update new filters array with active status based on cached active filters array
    filters.forEach(filter => {
      // check for undefined as aggregations may give back new keys
      if (state.activeFilters[filter.id] !== undefined) {
        filter.value.forEach(filterValue => {
          if (state.activeFilters[filter.id].Values.indexOf(filterValue.key) > -1) {
            filterValue.active = true;
          } else {
            filterValue.active = false;
          }
        });
      }
    });
    state.filters = [...filters];
  },
  setProductStock(state, productWithStock) {
    let index = state.products.findIndex(product => product.id === productWithStock.id);
    state.products[index] = productWithStock;
  },
  setProductPrice(state, productWithPrice) {
    let index = state.products.findIndex(product => product.id === productWithPrice.id);
    state.products[index] = productWithPrice;
  },
  setSeoInformation(state, productWithSEO) {
    let index = state.products.findIndex(product => product.id === productWithSEO.id);
    state.products[index] = productWithSEO;
  },
  setCustomerLists(state, productWithList) {
    let index = state.products.findIndex(product => product.id === productWithList.id);
    state.products[index] = productWithList;
  },
  setTotalProducts(state, totalProducts) {
    state.totalProducts = totalProducts;
  },
  setTotalPages(state) {
    state.totalPages = Math.ceil(state.totalProducts / state.pageSize);
  },
  initConfig(state, config) {
    state.rangeFrom = config.From;
    state.blockSize = config.BlockSize;
    state.aggregations = config.Aggregations;
    state.pricingQueryString = config.PricingQueryString;
    state.customerListId = config.CustomerListId;
    state.queryString = !config.QueryString ? '' : config.QueryString;
    state.pageSize = config.Size;
    state.pageTitle = config.CategoryName;
    state.pageSizes = config.PageSizes;
    state.layoutType = config.LayoutType;
    state.sortingOptions = config.SortingOptions;
    if (state.sortingOptions[0].Field !== '') {
      state.sort = state.sortingOptions[0].Field;
      state.sortingType = state.sortingOptions[0].Type;
    }
  },
  initSearchConfig(state, searchConfig) {
    state.searchConfig = { ...searchConfig };
  },
  updateConfigWithParameters(state, config) {
    state.rangeFrom = config.from;
    state.queryString = config.queryString;
    state.pageSize = config.pageSize;
    state.currentPage = config.currentPage;
  },
  changePage(state, pageNumber) {
    state.rangeFrom = (pageNumber - 1) * state.pageSize;
    state.currentPage = pageNumber;
    state.products = [];
  },
  changeLayout(state, layoutType) {
    state.layoutType = layoutType;
  },
  changePageSize(state, pageSize) {
    state.pageSize = pageSize;
  },
  changePageSorting(state, pageSorting) {
    state.sortingType = pageSorting.Type;
    state.sort = pageSorting.Field;
  },
  updateActiveFilters(state, { filterActive, filterId, filterValue, language }) {
    let activeFilters = { ...state.activeFilters };
    if (typeof activeFilters[filterId] === 'undefined' || activeFilters[filterId] == undefined) {
      activeFilters[filterId] = {};
    }
    if (filterActive) {
      let val = [filterValue];

      if (activeFilters[filterId].Values) {
        if (activeFilters[filterId].Values.length > 0) {
          activeFilters[filterId].Values.forEach(function (x) {
            val.push(x);
          });
        }
      }

      activeFilters[filterId] = { Values: val, Language: language, PartialSearch: false };
    } else {
      // BESPOKE PDA-242
      // Multiple filters can be selected, so delete the filter value in the array 
      // of activeFilters instead of the entire filter array
      if (activeFilters[filterId].Values.length > 1) {
        activeFilters[filterId].Values.splice(activeFilters[filterId].Values.indexOf(filterValue), 1);
      } else {
        delete activeFilters[filterId];
      }
      // END BESPOKE PDA-242
    }
    state.activeFilters = activeFilters;
  },
  // BESPOKE
  updateActiveRangeFilters(state, { filterActive, filterId, filterValue, language }) {
    let activeFilters = { ...state.activeFilters };

    if (typeof activeFilters[filterId] === 'undefined' || activeFilters[filterId] == undefined) {
      activeFilters[filterId] = {};
    }

    if (filterActive) {
      activeFilters[filterId] = { Values: filterValue, Language: language, PartialSearch: false };
    } else {
      delete activeFilters[filterId];
    }
    state.activeFilters = activeFilters;
  },
  // END BESPOKE
  setProductVariants(state, { products, productId }) {
    let index = state.products.findIndex(product => product.id === productId);
    state.products[index].setProductVariants(products);
  },
  toggleMobileFilters(state) {
    state.showMobileFilters = !state.showMobileFilters;
  },
  updateUrl(state, rootGetters) {

    // BESPOKE
    var priceRangeSpecCode = rootGetters.bespokeGlobalWs.priceRangeSpecCode;
    priceRangeSpecCode = priceRangeSpecCode !== '' ? priceRangeSpecCode : 'YTM5DSJDWO69NDUVRBES';
    // END BESPOKE

    let filters = {};
    if (Object.entries(state.activeFilters).length !== 0) {
      Object.keys(state.activeFilters).forEach(filter => {
        // BESPOKE TEBE
        // Since PX_SELL is a range filter, we need to limit the number of values that can be set in the
        // url's page parameters to the min and max value instead of every aggregation value that falls within
        // this range because there is  limit to the length of the url
        if (filter === priceRangeSpecCode) {
          var filterValues = state.activeFilters[filter].Values;
          var minFilterValue = filterValues[0];
          var maxFilterValue = filterValues[filterValues.length - 1];
          filters[filter] = state.activeFilters[filter];
          filters[filter].Values = [minFilterValue, maxFilterValue];
        } else {
          filters[filter] = state.activeFilters[filter];
        }
        // END BESPOKE TEBE
      });
    }
    const urlParams = createUrlParams({
      searchtext: state.queryString,
      from: state.rangeFrom,
      pageSize: state.pageSize,
      filters: filters
    });
    history.pushState({}, '', urlParams);
  }
};

const actions = {
  initElastic({ commit, dispatch }) {
    dispatch('readoutUrl');
    dispatch('getProducts');
  },
  readoutUrl({ getters, commit, rootGetters }) {
    if (window.location.search.length) {
      const params = getUrlParams(location.href);
      const from = parseInt(params.from) ? parseInt(params.from) : 0;
      const pageSize = parseInt(params.pageSize) ? parseInt(params.pageSize) : state.pageSize;
      const queryString = params.searchtext ? params.searchtext : state.queryString;
      const currentPage = from === 0 ? 1 : from / pageSize + 1;
      const filters = params.filters;

      if (filters !== undefined) {
        Object.keys(filters).forEach(function (x) {
          if (filters[x].length == 1) {
            if (filters[x][0].includes(',')) {
              filters[x] = filters[x][0].split(',');
            }
          }
        });
      }

      commit('updateConfigWithParameters', { from: from, pageSize: pageSize, queryString: queryString, currentPage: currentPage });

      // BESPOKE
      var priceRangeSpecCode = rootGetters.bespokeGlobalWs.priceRangeSpecCode;
      priceRangeSpecCode = priceRangeSpecCode !== '' ? priceRangeSpecCode : 'YTM5DSJDWO69NDUVRBES';
      // END BESPOKE

      if (typeof filters !== 'undefined') {
        Object.keys(filters).forEach(filter => {

          // convert minValue and maxValue to all possible aggr values in between
          if (filter === priceRangeSpecCode) {
            var storedRange = JSON.parse(localStorage.getItem('priceRangePX_SELL'));

            if (storedRange !== null) {
              commit('updateActiveRangeFilters', {
                filterActive: true,
                filterId: filter,
                filterValue: storedRange,
                language: rootGetters.language
              });
            }
          } else {
            filters[filter].forEach(x => {
              if (filter !== priceRangeSpecCode) {
                commit('updateActiveFilters', {
                  filterActive: true,
                  filterId: filter,
                  filterValue: x,
                  language: rootGetters.language
                });
              } else {

              }
            })
          }
        });
      }
    }
  },
  getProducts({ commit, getters, dispatch, rootGetters }) {
    commit('setLoadingStatus', true);
    if (getters.customerListId !== '') {
      const listEndpoint = `${rootGetters.productsFromList}?listId=${getters.customerListId}&debtorId=${rootGetters.customerId}`
      axios.post(listEndpoint)
        .then(res => {
          if (res.data) {
            commit('setTotalProducts', res.data.length);
            let pagerange = getters.pageRange;

            commit('setSortingOptions', []);
            commit('setProductCodes', res.data.slice(pagerange.from - 1, pagerange.to).map(x => {
              return { key: x, value: [x] };
            }));
            commit('noProductsFound', false);
            commit('setLoadingStatus', false);
            commit('setTotalPages');
            // BESPOKE
            // Pass rootGetters
            commit('updateUrl', rootGetters);
            // END BESPOKE
            dispatch('getProductInformation');
          } else {
            commit('noProductsFound', true);
          }
        });
    } else {
      axios.post(getters.elasticProductsEndpoint, getters.searchConfig)
        .then(res => {
          if (res.data.products) {
            commit('setProductCodes', res.data.products);
            commit('noProductsFound', false);

            // BESPOKE VLINT-60            
            var searchConfig = getters.searchConfig;
            searchConfig.StringProperties = null;
            axios.post(getters.elasticAggregationProductsEndpoint, getters.searchConfig)
              .then(res => {

                // BESPOKE VLINT
                // Filter by number of total results
                // Filter sorting 1 is to sort on the description of the filter value 
                if (rootGetters.filterSorting === 0) {
                  res.data.stringAggregations.forEach(filter => {
                    if (isNaN(filter.value[0].key)) {
                      filter = filter.value.sort((a, b) => {
                        var textA = a.key.toUpperCase();
                        var textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      });
                    } else {
                      filter = filter.value.sort((a, b) => a.key - b.key);
                    }
                  });
                }
                commit('setFilters', res.data.stringAggregations);
                // END BESPOKE VLINT 
              });

            commit('setTotalProducts', res.data.totalItems);
            commit('setLoadingStatus', false);
            commit('setTotalPages');
            // BESPOKE
            // Pass rootGetters
            commit('updateUrl', rootGetters);
            dispatch('getProductInformation');
          } else {
            commit('setFilters', null);
            commit('noProductsFound', true);
          }
        })
        .catch(error => {
          commit('noProductsFound', true);
        });
    }
  },
  getProductInformation({ commit, getters, dispatch, rootGetters }) {
    let mainProductCodes = getters.productCodes.map(code => code.key);
    axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));

        if (rootGetters.showStock) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        // BESPOKE TEBE 
        // Added || (rootGetters.userLoggedOn
        if (rootGetters.showPrices || rootGetters.userLoggedOn) {
          dispatch('getProductPrices', products);
        }

        if (rootGetters.showFavorites) {
          dispatch('getCustomerLists', products);
        }

        dispatch('getProductSeoInformation', products)

        commit('setProducts', products);
      });
  },
  getProductSeoInformation({ commit, getters, dispatch, rootGetters }, products) {
    let endpoint = rootGetters.seoInformationEndpoint + '/GetProductImageInformation';
    let productCodes = products.map(prod => { return prod.id });

    axios.post(endpoint, productCodes)
      .then(res => {
        res.data.forEach(SeoOject => {
          let product = products[products.findIndex(x => x.id === SeoOject.ProductId)];
          product.setSeoInformation(SeoOject);
          commit('setSeoInformation', product);
        });
      });
  },
  getProductPrices({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          //GoogleAnalytics GA4
          // Push products to datalayer once all product prices have been returned
          if (rootGetters.ga4) {
            productsFetched++;
            if (productsFetched === state.products.length) {
              dispatch('pushProductsViewedToDataLayer');
            }
          }
          commit('setProductPrice', product);
        });
      });
  },
  getProductVariants({ commit, dispatch, rootGetters, getters }, { variantCode, productId }) {
    const productVariantCodes = getters.productCodes.filter(code => code.key === variantCode)[0].value;
    axios.post(getters.productInformationEndpoint, productVariantCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        if (rootGetters.showStock) {
          products.forEach((product, index) => {
            axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
              .then(res => {
                product.setStock(res.data.d);
              });
          });
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }
        commit('setProductVariants', { products: products, productId: productId });
        dispatch('getProductVariantPrices', products);
      });
  },
  getProductVariantPrices({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
        });
      });
  },
  getCustomerLists({ commit, getters, rootGetters }, products) {
    const endpoint = `${rootGetters.getProductsInListsEndpoint}?debtorId=${rootGetters.customerId}`;
    axios.post(endpoint, getters.productCodes.map(code => code.key))
      .then(res => {
        Object.keys(res.data).forEach(prodCode => {
          let filteredProduct = products[products.findIndex(product => product.id === prodCode)];
          if (filteredProduct !== undefined) {
            filteredProduct.setCustomerLists(res.data[prodCode]);
            commit('setCustomerLists', filteredProduct);
          }
        });
      });
  },
  updateFilters({ commit, dispatch, rootGetters }, changedFilter) {

    // BESPOKE
    if (changedFilter.isRangeFilter) {
      commit('updateActiveRangeFilters', { ...changedFilter, language: rootGetters.language });
    } else {
      commit('updateActiveFilters', { ...changedFilter, language: rootGetters.language });
    }
    // END BESPOKE

    commit('changePage', 1);
    dispatch('getProducts');
  },
  getProductStock({ commit, rootGetters }, products) {
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          product.setStock(res.data.d);
          commit('setProductStock', product);
        });
    });
  },
  changePage({ commit, dispatch }, pageNumber) {
    commit('changePage', pageNumber);
    dispatch('getProducts');
  },
  changePageSize({ commit, dispatch }, pageSize) {
    commit('changePageSize', pageSize);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changePageSorting({ commit, dispatch }, pageSorting) {
    commit('changePageSorting', pageSorting);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changeLayout({ commit }, layoutType) {
    commit('changeLayout', layoutType);
  },
  toggleMobileFilters({ commit }) {
    commit('toggleMobileFilters');
  },
  // BESPOKE
  setNoProductsFound({ commit }, noProductsFound) {
    commit('noProductsFound', noProductsFound);
    commit('setProductCodes', []);
    commit('setProducts', []);
    commit('setTotalProducts', 0);
    commit('setTotalPages');
  },
  // END BESPOKE
  pushProductsViewedToDataLayer({ state, rootGetters }) {
    const list = state.queryString ? 'search results' : 'product overview';
    const productsPayload = state.products.map((product, index) => {
      return {
        name: product.shortDescription,
        id: product.id,
        price: product.prices[0].rawPrice,
        brand: product.brand,
        category: state.pageTitle,
        variant: '',
        list: list,
        position: index
      }
    });

    window.dataLayer.push({
      'event': 'productImpression',
      'ecommerce': {
        'currencyCode': rootGetters.currencyCode,
        'impressions': productsPayload
      }
    });
  },
  pushProductClickToDataLayer({ state }, productCode) {
    var position = 0;
    const product = state.products.filter((product, index) => {
      if (product.id === productCode) {
        position = index;
        return true;
      }
    })[0];
    const list = state.queryString ? 'search results' : 'product overview';

    window.dataLayer.push({
      'event': 'productClick',
      'ecommerce': {
        'currencyCode': state.currencyCode,
        'click': {
          'actionField': { 'list': list },
          'products': [{
            'name': product.shortDescription,
            'id': product.id,
            'price': product.prices[0].rawPrice,
            'brand': product.brand,
            'position': position
          }]
        }
      },
      'eventCallback': function () {
        document.location = product.targetUrl;
      }
    });
  }
  // END BESPOKE
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
